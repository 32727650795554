import React from "react"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import styled from "styled-components";
import PostText from "../styles/PostText";
import ContainerSmall from "../styles/ContainerSmall";
import StyledH1 from "../styles/StyledH1";

const PostWrapper = styled(ContainerSmall)`



  .blog-highlight {
    font-family: Nunito Sans, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 34px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.05em;
    color: #0D3745;
    padding-top: 30px;
    padding-bottom: 30px;
    border-top: 2px solid ${props => props.theme.blue};
    border-bottom: 2px solid ${props => props.theme.blue};
    margin-top: 50px;
    margin-bottom: 50px;
    @media (max-width: ${props => props.theme.screenSmMax}) {
      font-size: 1.75rem;
    }
  }
`;

const PostHeading = styled(StyledH1)`
  max-width: 873px;
  margin-top: 60px;
  margin-bottom: 30px;
`

const Subhead = styled.p`
  font-family: Nunito Sans, sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;

  a {
    color: ${props => props.theme.red};
  }
`;


export default function Scholarship() {

  return (
    <Layout grayFooter={true} hideForm={true}>

      <PostWrapper>
        <article>
          <header>
            <PostHeading>
              Clean Catalog Scholarship
            </PostHeading>
          </header>
          <Subhead>
            {/*<a href={'/blog/june-2022-scholarship/'}>We've announced the winners for our June 2022 scholarship.</a> Stay*/}
            {/*tuned for our December 2022 scholarship!*/}
            Our December 2024 scholarship is now closed. Stay tuned for winners and future scholarships!
            {/*<p>We're offering three $500 scholarships to be awarded January 2025. To apply, fill out the form below with*/}
            {/*  a very short description of why you need the scholarship.</p>*/}
            {/*<p>Scholarship will close December 31, 2024</p>*/}


            {/*<a href={"https://share.hsforms.com/1xJKrA6eUQVuXvuM534j8zQ468kn"}>Apply Here</a>*/}

          </Subhead>
          <PostText>

          </PostText>

        </article>

      </PostWrapper>


    </Layout>
  )

}

export function Head(props) {
  return (
    <Seo
      title="Clean Catalog Scholarship"
      description={'Three $500 scholarships offered twice a year by Clean Catalog.'}
      canonical={props.location.pathname}
    />
  )
}

