import React from "react"
import styled from "styled-components";
import Container from "../../styles/Container";
import {StaticImage} from "gatsby-plugin-image";
import ContainerSmall from "../../styles/ContainerSmall";


const LogosInterior = styled(ContainerSmall)`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 60px;
  &:nth-child(2) {
  padding-bottom: 30px;
  }
  
    &:nth-child(3) {
  padding-top: 30px;
  }

  > div {
    margin: 10px;
  }

   @media(max-width: ${props => props.theme.screenSmMax}) {
     flex-wrap: wrap;
     justify-content: space-around;
     > div {
      margin: 20px;
     }
   }
`;

const StyledLogosH2 = styled.h2`
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 900;
    margin-top: 80px;
    margin-bottom: 0;
    line-height: 54/46;
    text-align: center;
    letter-spacing: 0.03em;
    color: ${props => props.theme.black};
    margin-bottom: 15px;
    @media(max-width: ${props => props.theme.screenSmMax}) {
 
    }
`

const Logos = () => {

  return (
    <Container>
      <StyledLogosH2>Trusted By More Than 100 Colleges, Universities, And K-12 Districts</StyledLogosH2>
      <LogosInterior>
        <StaticImage
          src={'../../../content/assets/logos/logo-villanova.png'}
          alt="Villanova University Logo"
          placeholder="blurred"
          layout="fixed"
          width={130}
        />
        <StaticImage
          src={'../../../content/assets/logos/logo-fullsail.png'}
          alt="Full Sail University Logo"
          placeholder="blurred"
          layout="fixed"
          width={130}
        />
        <StaticImage
          src={'../../../content/assets/logos/paul-mitchell-logo.png'}
          alt="Paul Mitchell Schools Logo"
          placeholder="blurred"
          layout="fixed"
          width={130}
        />

        <StaticImage
          src={'../../../content/assets/logos/uti-logo.png'}
          alt="Universal Technical Institute Logo"
          placeholder="blurred"
          layout="fixed"
          width={130}
        />

        <StaticImage
          src={'../../../content/assets/logos/panola-logo-old.svg'}
          alt="Panola College Logo"
          placeholder="blurred"
          layout="fixed"
          width={110}
        />

      </LogosInterior>
      <LogosInterior>
        <StaticImage
          src={'../../../content/assets/logos/logo-cia.png'}
          alt="Culinary Institute of America Logo"
          placeholder="blurred"
          layout="fixed"
          width={150}
        />
        <StaticImage
          src={'../../../content/assets/logos/logo-concorde.png'}
          alt="Concorde Career Colleges Logo"
          placeholder="blurred"
          layout="fixed"
          width={150}
        />
        <StaticImage
          src={'../../../content/assets/logos/logo-ccsnh.png'}
          alt="Community College System of New Hampshire Logo"
          placeholder="blurred"
          layout="fixed"
          width={150}
        />
        <StaticImage
          src={'../../../content/assets/logos/ndm-logo.png'}
          alt="Notre Dame of Maryland University Logo"
          placeholder="blurred"
          layout="fixed"
          width={150}
        />

        <StaticImage
          src={'../../../content/assets/logos/logo-bristol.png'}
          alt="Bristol Community College Logo"
          placeholder="blurred"
          layout="fixed"
          width={150}
        />
      </LogosInterior>
    </Container>
  );
}

export default Logos;
